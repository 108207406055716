import {Card, Checkbox, Input, Layout, Table} from "antd";
import {ArrowLeft2, ArrowRight2, Data2, House, MoneyTime, TransactionMinus} from "iconsax-react";
import AdminLayout from "src/Layouts/Main";
import {
    AlertDialog,
    AlertDialogAction,
    AlertDialogCancel,
    AlertDialogContent,
    AlertDialogDescription,
    AlertDialogFooter,
    AlertDialogHeader
} from "src/components/ui/alert-dialog";
import {CardContent, CardHeader} from "src/components/ui/card";
import toast, {Toaster} from "react-hot-toast";
import {HashLoader} from "react-spinners";
import React, {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {useAppDispatch, useAppSelector} from "src/redux/hooks";
import {setIsAuth, setUserToken} from "src/redux/slices/user_slice";
import {
    DropdownMenu,
    DropdownMenuContent, DropdownMenuGroup, DropdownMenuItem,
    DropdownMenuLabel, DropdownMenuSeparator,
    DropdownMenuTrigger
} from "../../../components/ui/dropdown-menu";
import {Button} from "../../../components/ui/button";
import {Icon} from "@iconify/react";
import * as XLSX from "xlsx";
import {saveAs} from "file-saver";
import {ColumnsType} from "antd/es/table";
import {SortOrder} from "antd/es/table/interface";


const {Content} = Layout;

interface Transaction {
    id: number;
    amount: number;
    currency: string;
    status: string;
    checkout_id: string;
    trackable_data: object;
    provider: string;
    total: number;
    user: {
        id: number;
        name: string;
        phone: string;
    };
    reservation: {
        id: number;
        total: number;
    };


}


export default function Transactions() {
    const [loading, setLoading] = React.useState(true)
    const navigate = useNavigate();
    const token = useAppSelector((state) => state.user.token)
    const dispatch = useAppDispatch()
    const [data, setData] = useState([])
    const [searchText, setSearchText] = useState("")
    const [page, setPage] = useState(1)
    const [pageSize, setPageSize] = useState(10)
    const [total, setTotal] = useState(0)


    async function fetchData() {
        try {
            const response = await fetch(`${process.env.REACT_APP_DEV_ADMIN_API_URL}transactions?page=${page}&limit=${pageSize}&sort[by]=created_at`, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": "Bearer " + token,
                    "Accept-Language": "ar",
                }
            })
            const data = await response.json();
            console.log(data)
            if (response.status == 200) {
                setLoading(false)
                setData(data.data.items)
                setPage(data.data.pagination.currentPage)
                setPageSize(data.data.pagination.perPage)
                setTotal(data.data.pagination.total)
            } else {

            }
        } catch (error) {
        }

    }


    useEffect(() => {
        window.document.title = "العمليات"
        fetchData()

    }, [page, pageSize, loading])
    const columns: ColumnsType<Transaction> = [
        {
            title: "رقم العملية",
            dataIndex: "id",
            key: "id",
            sorter: (a, b) => {
                const valueA = a.id ?? Infinity;
                const valueB = b.id ?? Infinity;
                return valueA - valueB;
            },
            defaultSortOrder: 'descend',
        },
        {
            title: "المبلغ",
            dataIndex: "reservation",
            key: "reservation",
            render: (reservation: any) => reservation?.total || 'غير متوفر',

        },
        // {
        //   title: "العملة",
        //     dataIndex: "currency",
        //     key: "currency",
        // },
        {
            title: "الحالة",
            dataIndex: "status",
            key: "status",

        },
        {
            title: "طريقة الدفع",
            dataIndex: "provider",
            key: "provider",

        },


        {
            title: "جوال العميل",
            dataIndex: "user",
            key: "user",
            render: (user: any) => user?.phone || "غير متوفر",
        },
        {
            title: "اسم العميل",
            dataIndex: "user",
            key: "user",
            render: (user: any) => user?.name || 'غير متوفر',
        },
        {
            title: "كود العميل",
            dataIndex: "user",
            key: "user",
            render: (user: any) => user?.id || 'غير متوفر',
        },
        {
            title: "رقم الحجز",
            dataIndex: "reservation",
            key: "reservation",
            render: (reservation: any) => reservation?.id || 'غير متوفر',
        },
        // {
        //     title: "رقم الحجز",
        //     dataIndex: "reservation",
        //     key: "reservation",
        //     render: (reservation: any) => reservation?.id || 'غير متوفر',
        // },


        // {
        //     title: "Checkout ID",
        //     dataIndex: "checkout_id",
        //     key: "checkout_id",
        // },
        {
            title: "Action",
            key: "action",
            render: () => (
                <>
                    <DropdownMenu>
                        <DropdownMenuTrigger>الإجراءات</DropdownMenuTrigger>
                        <DropdownMenuContent>
                            <DropdownMenuLabel>الإجراءات</DropdownMenuLabel>
                            <DropdownMenuSeparator/>
                            <DropdownMenuItem>
                                تحت التطوير
                            </DropdownMenuItem>
                        </DropdownMenuContent>
                    </DropdownMenu>
                </>
            ),
        },
    ];

    const itemRender = (_: any, type: string, originalElement: any) => {
        if (type === "prev") {
            return <ArrowRight2 size="32" color="#333"/>;
        }
        if (type === "next") {
            return <ArrowLeft2 size="32" color="#333"/>;
        }
        return originalElement;
    };

    const createExportData = (data: Transaction[]): any[] => {
        return data.reverse().map(item => ({
            "رقم العملية": item.id,
            "المبلغ": item.reservation?.total,
            "الحالة": item.status,
            //@ts-ignore
            "جوال العميل": item.user?.phone || 'غير متوفر',
            //@ts-ignore
            "اسم العميل": item.user?.name || 'غير متوفر',
            //@ts-ignore
            "كود العميل": item.user?.id || 'غير متوفر',
            //@ts-ignore
            "رقم الحجز": item.reservation?.id || 'غير متوفر',
            "طريقة الدفع": item.provider || 'غير متوفر',
        }));
    };

// Function to export to Excel
    const exportToExcel = (data: Transaction[]) => {
        const exportData = createExportData(data);

        // Create the worksheet
        const worksheet = XLSX.utils.json_to_sheet(exportData);

        // Create the workbook and add the worksheet
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "Transactions");

        // Generate the Excel file and trigger the download
        const excelBuffer = XLSX.write(workbook, {bookType: 'xlsx', type: 'array'});
        const blob = new Blob([excelBuffer], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'});

        saveAs(blob, `Transactions.xlsx`);
    };
    const exportAllData = async () => {
        const response = await fetch(`${process.env.REACT_APP_DEV_ADMIN_API_URL}transactions?sort[by]=created_at&limit=10000`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + token,
                "Accept-Language": "ar",
            }
        })
        const data = await response.json();
        const worksheet = XLSX.utils.json_to_sheet(createExportData(data.data.items));
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");


        const excelBuffer = XLSX.write(workbook, {bookType: 'xlsx', type: 'array'});
        const blob = new Blob([excelBuffer], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'});

        saveAs(blob, `Transactions.xlsx`);


    }
    return (
        <>


            <>
                <div><Toaster/></div>

                <AdminLayout></AdminLayout>
                <Content style={{direction: "rtl"}}>
                    <div className="mx-6 mt-6">
                        <Card>
                            <CardHeader>

                                <div className="flex items-center justify-between">
                                    <div className="flex items-center">
                                        <div style={{background: "#f9f9f9"}} className={"mx-2 p-2 rounded-md"}>
                                            <MoneyTime
                                                color="#475467"
                                                variant="Bulk"
                                                size={32}
                                            />

                                        </div>
                                        <div>
                                            <h1 className="text-xl">
                                                العمليات
                                            </h1>
                                        </div>


                                    </div>
                                    <DropdownMenu>
                                        <DropdownMenuTrigger asChild>
                                            <Button>
                                                تصدير
                                            </Button>

                                        </DropdownMenuTrigger>
                                        <DropdownMenuContent className="w-56">
                                            <DropdownMenuLabel>الخيارات</DropdownMenuLabel>
                                            <DropdownMenuSeparator/>
                                            <DropdownMenuGroup>
                                                <DropdownMenuItem onClick={() => exportToExcel(data)} className="gap-2">
                                                    <Icon width={32} icon="vscode-icons:file-type-excel"/>
                                                    <span>تصدير الى Excel</span>
                                                </DropdownMenuItem>
                                                {/*<DropdownMenuItem>*/}
                                                {/*  <Icon width={32} icon="ant-design:file-pdf-filled"  style={{color: "#333333"}} />*/}
                                                {/*  <span>تصدير الى pdf</span>*/}
                                                {/*</DropdownMenuItem>*/}

                                                <DropdownMenuItem onClick={() => {
                                                    try {
                                                        toast.promise(exportAllData(), {
                                                            loading: "جاري التحميل",
                                                            success: "تم تصدير البيانات بنجاح",
                                                            error: "حدث خطأ ما",
                                                        });
                                                    } catch (error) {
                                                        console.log(error);
                                                        toast.error("حدث خطأ ما");
                                                    }
                                                }}
                                                                  className="gap-2">
                                                    <Icon width={32} icon="vscode-icons:file-type-excel"/>
                                                    <span>
                                                            تصدير  كل البيانات الى ملف اكسل
                                                        </span>
                                                </DropdownMenuItem>


                                            </DropdownMenuGroup>

                                        </DropdownMenuContent>
                                    </DropdownMenu>

                                </div>

                            </CardHeader>
                        </Card>
                    </div>
                    <div className="mx-6 mt-6">
                        <Input
                            onChange={(e) => setSearchText(e.target.value)}
                            placeholder="
                                ابحث برقم العملية
                            "
                        />
                    </div>
                    <div className="mx-6 mt-6">

                        <Table dataSource={
                            data.filter((item: any) => {
                                return item.id.toString().includes(searchText)
                            })
                        }
                               rowKey="id"
                               showHeader={true}
                               bordered={true}
                               scroll={{x: 'max-content'}}
                               locale={{
                                   emptyText: (
                                       <div className="flex flex-col items-center justify-center">
                                           <Data2 size={64} color="#6B7280"/>
                                           <span className="mt-2 text-xl font-bold text-gray-600">
                                             لا توجد بيانات
                                        </span>
                                       </div>
                                   ),

                               }}
                               virtual={true}
                               pagination={{
                                   showSizeChanger: true,
                                   showQuickJumper: true,
                                   showTotal: (total) => `Total ${total} items`,
                                   total: total,
                                   current: page,
                                   position: ["bottomCenter"],
                                   size: "default",
                                   itemRender: itemRender

                               }}
                               onChange={(pagination, filters, sorter, extra) => {
                                   console.log('params', pagination, filters, sorter, extra);
                                   setPage(pagination.current ?? 1)
                                   setPageSize(pagination.pageSize ?? 10)
                                   setLoading(true)
                               }}
                               sortDirections={["ascend", "descend"]}
                               showSorterTooltip={true}
                               loading={loading}
                               columns={columns}/>;


                    </div>

                </Content>
            </>


        </>
    )
}