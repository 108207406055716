import { Card, Layout } from "antd";
import { useEffect, useState } from "react";
import AdminLayout from "../../../Layouts/Main";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import {
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from "../../../components/ui/card";
import React from "react";
import toast, { Toaster } from "react-hot-toast";
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';

import { HashLoader, PropagateLoader } from "react-spinners";
import {Link, useLocation, useNavigate} from "react-router-dom";
import {
  CalendarAdd,
  CalendarRemove,
  Clock,
  CardReceive,
  TransactionMinus, Star, User, Personalcard, House,
} from "iconsax-react";
import ExportToPDF from "../../../components/ExportToPDF";
import moment from "moment";

const { Content } = Layout;

export default function ViewReservation() {
  const [loading, setLoading] = React.useState(false);
  const navigate = useNavigate();
  const token = useAppSelector((state) => state.user.token)
  const dispatch = useAppDispatch()
  const state = useLocation().state
  console.log(state)




  return (
    <>
      {loading ? (
        <div className="flex justify-center items-center h-screen">
          <HashLoader size={64} color="#36d7b7" />
        </div>
      ) : (
        <>
          <div>
            <Toaster />
          </div>

          <AdminLayout></AdminLayout>
          <Content style={{direction:"rtl"}}>
            <div className="mx-6 mt-6">
              <Card>
                <CardHeader>
                  <div className="flex items-center justify-between">
                    <div className="flex items-center gap-2">
                      <div style={{background: "#f9f9f9"}} className={"mx-2 p-2 rounded-md"}>
                        <TransactionMinus size="48" color="#2dacf4" variant="Bulk" />

                      </div>
                      <h1 className="text-xl font-bold">
                        حجز رقم #{state?.id}
                      </h1>

                    </div>
                    <ExportToPDF idRef="booking-container" name={`${state.username}-booking`} />



                  </div>
                </CardHeader>
              </Card>
            </div>

            <div  id="booking-container" className="grid grid-cols-1 mx-6 mt-6">
              <Card>
                <CardHeader>
                  <img width={128} height={128} src="/assets/imgs/logo-black.svg" alt=""/>

                  <h1 className="text-xl font-bold">معلومات الحجز</h1>
                </CardHeader>
                <CardContent>
                  <div className="grid grid-cols-5 gap-6">
                    <div>
                      <h1 className="text-xl">الإجمالي</h1>
                      <h1 className="text-2xl font-bold">
                        {state?.total}
                      </h1>
                    </div>
                    <div>
                      <h1 className="text-xl">
                        رقم الحجز
                      </h1>
                      <h1 className="text-2xl font-bold">
                        {state?.id}
                      </h1>
                    </div>
                    <div>
                      <h1 className="text-xl">المجموع الفرعي</h1>
                      <h1 className="text-2xl font-bold">
                        {state?.sub_total}
                      </h1>
                    </div>
                    <div>
                      <h1 className="text-xl">الضريبة</h1>
                      <h1 className="text-2xl font-bold">
                        {state?.tax}
                      </h1>
                    </div>
                    <div>
                      <h1 className="text-xl">الخدمة</h1>
                      <h1 className="text-2xl font-bold">
                        {state?.service}
                      </h1>
                    </div>
                    <div>
                      <h1 className="text-xl">اسم المستخدم</h1>
                      <h1 className="text-2xl font-bold">
                        {state?.username}
                      </h1>
                    </div>
                    <div>
                      <h1 className="text-xl">
                        كود العميل
                      </h1>
                      <h1 className="text-2xl font-bold">
                        {state?.user?.id}
                      </h1>
                    </div>
                    <div>
                      <h1 className="text-xl">رقم الهاتف</h1>
                      <h1 className="text-2xl font-bold">
                        {state?.user.phone}
                      </h1>
                    </div>

                    <div>
                      <h1 className="text-xl">الحالة</h1>
                      <h1 className="text-2xl font-bold">
                        {state?.status}
                      </h1>
                    </div>
                    <div>
                      <h1 className="text-xl">طريقة الدفع</h1>
                      <h1 className="text-2xl font-bold">
                        {state?.payment_brand} - {state?.payment_method}
                      </h1>
                    </div>
                    <div>
                      <h1 className="text-xl">حالة الدفع</h1>
                      <h1 className="text-2xl font-bold">
                        {state?.payment_status}
                      </h1>
                    </div>
                    <div>
                      <h1 className="text-xl">تاريخ الحجز من</h1>
                      <h1 className="text-2xl font-bold">
                        {state?.reserved_from}
                      </h1>
                    </div>
                    <div>
                      <h1 className="text-xl">تاريخ الحجز إلى</h1>
                      <h1 className="text-2xl font-bold">
                        {state?.reserved_to}
                      </h1>
                    </div>
                    <div>
                      <h1 className="text-xl">
                        عدد أيام الحجز
                      </h1>
                      <h1 className="text-2xl font-bold">
                        {state?.reserved_from && state?.reserved_to ? moment(state?.reserved_to).diff(moment(state?.reserved_from), 'days') : 0}
                      </h1>
                    </div>
                    <div>
                      <h1 className="text-xl">نوع الإيجار</h1>
                      <h1 className="text-2xl font-bold">
                        {state?.type}
                      </h1>
                    </div>
                    <div>
                      <h1 className="text-xl">اسم العقار</h1>
                      <h1 className="text-2xl font-bold">
                        {state?.property_name}
                      </h1>
                    </div>
                    <div>
                      <h1 className="text-xl">
                        كود العقار
                      </h1>
                      <h1 className="text-2xl font-bold">
                        {state?.property?.id}
                      </h1>
                    </div>

                    <div>
                      <h1 className="text-xl">اسم المضيف</h1>
                      <h1 className="text-2xl font-bold">
                        {state?.property.owner.name}
                      </h1>
                    </div>
                    <div>
                      <h1 className="text-xl">كود المضيف</h1>
                      <h1 className="text-2xl font-bold">
                        {state?.property.owner.id}
                      </h1>
                    </div>

                    <div>
                      <h1 className="text-xl">رقم هاتف المضيف</h1>
                      <h1 className="text-2xl font-bold">
                        {state?.property.owner.phone}
                      </h1>
                    </div>
                    <div>
                      <h1 className="text-xl">تاريخ الإنشاء</h1>
                      <h1 className="text-2xl font-bold">
                        {moment(state?.created_at).format("YYYY-MM-DD")}
                      </h1>
                    </div>
                    <div>
                      <h1 className="text-xl">
                        سعر الوحده الايام العاديه
                      </h1>
                      <h1 className="text-2xl font-bold">
                        {state?.property?.price}
                      </h1>
                    </div>
                    <div>
                      <h1 className="text-xl">
                        سعر الوحده يوم الخميس
                      </h1>
                      <h1 className="text-2xl font-bold">
                        {state?.property?.prices?.thursday}
                      </h1>
                    </div>

                    <div>
                      <h1 className="text-xl">
                        سعر الوحده يوم الجمعه
                      </h1>
                      <h1 className="text-2xl font-bold">
                        {state?.property?.prices?.friday}
                      </h1>

                    </div>
                    <div>
                      <h1 className="text-xl">
                        سعر الوحده يوم السبت
                      </h1>
                      <h1 className="text-2xl font-bold">
                        {state?.property?.prices?.saturday}
                      </h1>
                    </div>

                  </div>

                </CardContent>
              </Card>
            </div>


          </Content>
        </>
      )}
    </>
  );
}
