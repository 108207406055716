import {Card, Checkbox, Input, Layout, Table} from "antd";
import {ArrowLeft2, ArrowRight2, Data2, House} from "iconsax-react";
import AdminLayout from "src/Layouts/Main";
import {
    AlertDialog,
    AlertDialogAction,
    AlertDialogCancel,
    AlertDialogContent,
    AlertDialogDescription,
    AlertDialogFooter,
    AlertDialogHeader
} from "src/components/ui/alert-dialog";
import {CardContent, CardHeader} from "src/components/ui/card";
import toast, {Toaster} from "react-hot-toast";
import {HashLoader} from "react-spinners";
import React, {useEffect, useState} from "react";
import {useNavigate, useParams, useSearchParams} from "react-router-dom";
import {useAppDispatch, useAppSelector} from "src/redux/hooks";
import {setIsAuth, setUserToken} from "src/redux/slices/user_slice";
import {
    DropdownMenu,
    DropdownMenuContent, DropdownMenuGroup, DropdownMenuItem,
    DropdownMenuLabel, DropdownMenuSeparator,
    DropdownMenuTrigger
} from "../../../components/ui/dropdown-menu";
import {Button} from "../../../components/ui/button";
import {Icon} from "@iconify/react";
import * as XLSX from "xlsx";
import {saveAs} from "file-saver";
import {ColumnsType} from "antd/es/table";
import {SortOrder} from "antd/es/table/interface";


const {Content} = Layout;

interface Property {
    id: number;
    first_name: string;
    last_name: string;
    email: string;
    phone: string;
    units_count: number;
    total_reservation: number;
    area: number;
    cancelation_days: number;
    category: {
        id: number;
        title: string;
        icon_url: string;
    };
    check_in_time: string;
    check_out_time: string;
    city: {
        id: number;
        is_active: number;
        title: string;
        created_at: string;
    };
    country: {
        id: number;
        is_active: number;
        title: string;
        created_at: string;
    };
    created_at: string;
    description: string;
    facilities: {
        id: number;
        title: string;
        icon_url: string;
    }[];
    facility_options: any[];
    hour_price: number;
    hour_prices: any[];
    images: {
        id: number;
        url: string;
    }[];
    latitude: number;
    longitude: number;
    monthly_price: number;
    nearest_facilities: any[];
    num_of_bathrooms: number;
    num_of_bedrooms: number;
    num_of_double_beds: number;
    num_of_guests: number;
    num_of_single_beds: number;
    owner: {
        id: number;
        name: string;
        email: string;
        email_verified_at: string | null;
    };
    owner_reviews: number;
    price: number;
    price_per_hour: number;
    price_per_month: number;
    prices: {
        id: number;
        saturday: number;
        sunday: number;
        monday: number;
        tuesday: number;
        wednesday: number;
        thursday: number;
    };
    rate: number;
    refund_insurance: number;
    reviews: {
        id: number;
        rate: number;
        review: string | null;
        customer_id: number;
        is_published: number;
        is_host: number;
        hosting_request: number;
    }[];
    reviews_count: number;
    status: string;
    system_cancelation: number;
    title: string;
    translation: {
        id: number;
        category_type: string;
        category_id: number;
        key: string;
    }[];
    updated_at: string;
    views: number;
}

export default function PropertiesWithHosts() {
    const [loading, setLoading] = React.useState(true)
    const navigate = useNavigate();
    const token = useAppSelector((state) => state.user.token)
    const dispatch = useAppDispatch()
    const [data, setData] = useState([])
    const [searchText, setSearchText] = useState("")
    const [currentFirstNameSort, setCurrentFirstNameSort] = useState<'ascend' | 'descend' | null>("ascend");
    const [currentLastNameSort, setCurrentLastNameSort] = useState<'ascend' | 'descend' | null>("ascend");
    const {id} = useParams(); // Get the path parameter
    const [page, setPage] = useState(1)
    const [pageSize, setPageSize] = useState(10)
    const [total, setTotal] = useState(0)

    async function fetchData() {
        try {
            const response = await fetch(`${process.env.REACT_APP_DEV_ADMIN_API_URL}properties?page=${page}&limit=${pageSize}&sort[by]=created_at`, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": "Bearer " + token,
                    "Accept-Language": "ar"
                }
            })
            const data = await response.json();
            console.log(data)
            if (response.status == 200) {
                setLoading(false)
                setData(data.data.items)
            } else {

            }
        } catch (error) {
            toast("حدث خطأ ما", {icon: "🔥"});
        }

    }


    useEffect(() => {
        window.document.title = "الوحدات السكنية مع أصحابها"
        console.log(currentFirstNameSort)
        fetchData()

    }, [])
    const columns: ColumnsType<Property> = [
        {
            title: "id",
            dataIndex: "id",
            key: "id",
            sorter: (a, b) => {
                const valueA = a.id ?? Infinity;
                const valueB = b.id ?? Infinity;
                return valueA - valueB;
            },
            defaultSortOrder: 'ascend',
        },


        {
            title: "Area",
            dataIndex: "area",
            key: "area",
            sorter: (a, b) => {
                const valueA = a.area ?? Infinity;
                const valueB = b.area ?? Infinity;
                return valueA - valueB;
            },
        },
        {
            title: "Cancelation Days",
            dataIndex: "cancelation_days",
            key: "cancelation_days",
            sorter: (a, b) => {
                const valueA = a.cancelation_days ?? Infinity;
                const valueB = b.cancelation_days ?? Infinity;
                return valueA - valueB;
            },
        },
        {
            title: "Category",
            dataIndex: "category",
            key: "category",
            render: (category) => category?.title,
        },
        {
            title: "Check In Time",
            dataIndex: "check_in_time",
            key: "check_in_time",
        },
        {
            title: "Check Out Time",
            dataIndex: "check_out_time",
            key: "check_out_time",
        },
        {
            title: "City",
            dataIndex: "city",
            key: "city",
            render: (city) => city?.title,
        },
        {
            title: "Country",
            dataIndex: "country",
            key: "country",
            render: (country) => country?.title,
        },
        {
            title: "Created At",
            dataIndex: "created_at",
            key: "created_at",
        },


        {
            title: "Hour Price",
            dataIndex: "hour_price",
            key: "hour_price",
            sorter: (a, b) => {
                const valueA = a.hour_price ?? Infinity;
                const valueB = b.hour_price ?? Infinity;
                return valueA - valueB;
            },
        },
        {
            title: "Latitude",
            dataIndex: "latitude",
            key: "latitude",
            sorter: (a, b) => {
                const valueA = a.latitude ?? Infinity;
                const valueB = b.latitude ?? Infinity;
                return valueA - valueB;
            },
        },
        {
            title: "Longitude",
            dataIndex: "longitude",
            key: "longitude",
            sorter: (a, b) => {
                const valueA = a.longitude ?? Infinity;
                const valueB = b.longitude ?? Infinity;
                return valueA - valueB;
            },
        },
        {
            title: "Monthly Price",
            dataIndex: "monthly_price",
            key: "monthly_price",
            sorter: (a, b) => {
                const valueA = a.monthly_price ?? Infinity;
                const valueB = b.monthly_price ?? Infinity;
                return valueA - valueB;
            },
        },
        {
            title: "Number of Bathrooms",
            dataIndex: "num_of_bathrooms",
            key: "num_of_bathrooms",
            sorter: (a, b) => {
                const valueA = a.num_of_bathrooms ?? Infinity;
                const valueB = b.num_of_bathrooms ?? Infinity;
                return valueA - valueB;
            },
        },
        {
            title: "Number of Bedrooms",
            dataIndex: "num_of_bedrooms",
            key: "num_of_bedrooms",
            sorter: (a, b) => {
                const valueA = a.num_of_bedrooms ?? Infinity;
                const valueB = b.num_of_bedrooms ?? Infinity;
                return valueA - valueB;
            },
        },
        {
            title: "Number of Double Beds",
            dataIndex: "num_of_double_beds",
            key: "num_of_double_beds",
            sorter: (a, b) => {
                const valueA = a.num_of_double_beds ?? Infinity;
                const valueB = b.num_of_double_beds ?? Infinity;
                return valueA - valueB;
            },
        },
        {
            title: "Number of Guests",
            dataIndex: "num_of_guests",
            key: "num_of_guests",
            sorter: (a, b) => {
                const valueA = a.num_of_guests ?? Infinity;
                const valueB = b.num_of_guests ?? Infinity;
                return valueA - valueB;
            },
        },
        {
            title: "Number of Single Beds",
            dataIndex: "num_of_single_beds",
            key: "num_of_single_beds",
            sorter: (a, b) => {
                const valueA = a.num_of_single_beds ?? Infinity;
                const valueB = b.num_of_single_beds ?? Infinity;
                return valueA - valueB;
            },
        },
        {
            title: "Owner",
            dataIndex: "owner",
            key: "owner",
            render: (owner) => owner?.name,
        },
        {
            title: "Owner Id",
            dataIndex: "owner id",
            key: "owner id",
            render: () =>
                <>
                    {id ? <span>{id}</span> : null}
                </>
        },

        {
            title: "Thursday Price",
            dataIndex: ["prices", "thursday"],
            key: "prices.thursday",
        },
        {
            title: "Friday Price",
            dataIndex: ["prices", "friday"],
            key: "prices.friday",
        },
        {
            title: "Saturday Price",
            dataIndex: ["prices", "saturday"],
            key: "prices.saturday",
        },
        {
            title: "Rest Days Price",
            dataIndex: "price",
            key: "price",
            sorter: (a, b) => {
                const valueA = a.price ?? Infinity;
                const valueB = b.price ?? Infinity;
                return valueA - valueB;
            },
        },
        {
            title: "Price Per Hour",
            dataIndex: "price_per_hour",
            key: "price_per_hour",
            sorter: (a, b) => {
                const valueA = a.price_per_hour ?? Infinity;
                const valueB = b.price_per_hour ?? Infinity;
                return valueA - valueB;
            },
        },

        {
            title: "Price Per Month",
            dataIndex: "price_per_month",
            key: "price_per_month",
            sorter: (a, b) => {
                const valueA = a.price_per_month ?? Infinity;
                const valueB = b.price_per_month ?? Infinity;
                return valueA - valueB;
            },
        },

        {
            title: "Rate",
            dataIndex: "rate",
            key: "rate",
            sorter: (a, b) => {
                const valueA = a.rate ?? Infinity;
                const valueB = b.rate ?? Infinity;
                return valueA - valueB;
            },
        },
        {
            title: "Refund Insurance",
            dataIndex: "refund_insurance",
            key: "refund_insurance",
            sorter: (a, b) => {
                const valueA = a.refund_insurance ?? Infinity;
                const valueB = b.refund_insurance ?? Infinity;
                return valueA - valueB;
            },
        },

        {
            title: "Reviews Count",
            dataIndex: "reviews_count",
            key: "reviews_count",
            sorter: (a, b) => {
                const valueA = a.reviews_count ?? Infinity;
                const valueB = b.reviews_count ?? Infinity;
                return valueA - valueB;
            },
        },
        {
            title: "Status",
            dataIndex: "status",
            key: "status",
        },
        {
            title: "System Cancelation",
            dataIndex: "system_cancelation",
            key: "system_cancelation",
            sorter: (a, b) => {
                const valueA = a.system_cancelation ?? Infinity;
                const valueB = b.system_cancelation ?? Infinity;
                return valueA - valueB;
            },
        },
        {
            title: "Title",
            dataIndex: "title",
            key: "title",
            render: (title) => (
                <>
                    {title
                        ? <span>{title}</span>
                        : null
                    }

                </>
            )
        },
        {
            title: "Updated At",
            dataIndex: "updated_at",
            key: "updated_at",
        },
        {
            title: "Views",
            dataIndex: "views",
            key: "views",
            sorter: (a, b) => {
                const valueA = a.views ?? Infinity;
                const valueB = b.views ?? Infinity;
                return valueA - valueB;
            },
        },
        {
            title: "Action",
            key: "action",
            render: () => (
                <>
                    <DropdownMenu>
                        <DropdownMenuTrigger>الإجراءات</DropdownMenuTrigger>
                        <DropdownMenuContent>
                            <DropdownMenuLabel>الإجراءات</DropdownMenuLabel>
                            <DropdownMenuSeparator/>
                            <DropdownMenuItem>
                                تحت التطوير
                            </DropdownMenuItem>
                        </DropdownMenuContent>
                    </DropdownMenu>
                </>
            ),
        },
    ];
   const serializeIds = (data: any[]) => {
    return data.map((item) => {
        return {
            "id": item.id ?? null,
            "Area": item.area ?? null,
            "Cancelation Days": item.cancelation_days ?? null,
            "Category": item.category?.title ?? null,
            "Check In Time": item.check_in_time ?? null,
            "Check Out Time": item.check_out_time ?? null,
            "City": item.city?.title ?? null,
            "Country": item.country?.title ?? null,
            "Created At": item.created_at ?? null,
            "Hour Price": item.hour_price ?? null,
            "Latitude": item.latitude ?? null,
            "Longitude": item.longitude ?? null,
            "Monthly Price": item.monthly_price ?? null,
            "Number of Bathrooms": item.num_of_bathrooms ?? null,
            "Number of Bedrooms": item.num_of_bedrooms ?? null,
            "Number of Double Beds": item.num_of_double_beds ?? null,
            "Number of Guests": item.num_of_guests ?? null,
            "Number of Single Beds": item.num_of_single_beds ?? null,
            "Owner": item.owner?.name ?? null,
            "Owner Id": item.owner?.id ?? null,
            "Thursday Price": item.prices?.thursday ?? null,
            "Friday Price": item.prices?.friday ?? null,
            "Saturday Price": item.prices?.saturday ?? null,
            "Rest Days Price": item.price ?? null,
            "Price Per Hour": item.price_per_hour ?? null,
            "Price Per Month": item.price_per_month ?? null,
            "Rate": item.rate ?? null,
            "Refund Insurance": item.refund_insurance ?? null,
            "Reviews Count": item.reviews_count ?? null,
            "Status": item.status ?? null,
            "System Cancelation": item.system_cancelation ?? null,
            "Title": item.title ?? null,
            "Updated At": item.updated_at ?? null,
            "Views": item.views ?? null,
        };
    });
};

    const itemRender = (_: any, type: string, originalElement: any) => {
        if (type === "prev") {
            return <ArrowRight2 size="32" color="#333"/>;
        }
        if (type === "next") {
            return <ArrowLeft2 size="32" color="#333"/>;
        }
        return originalElement;
    };
    const exportToExcel = (data:any) => {
        const serializedData = serializeIds(data);
        const worksheet = XLSX.utils.json_to_sheet(serializedData);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");

        const excelBuffer = XLSX.write(workbook, {bookType: 'xlsx', type: 'array'});
        const blob = new Blob([excelBuffer], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'});

        saveAs(blob, `PropertyWithHosts.xlsx`);
    };


    const exportAllData = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_DEV_ADMIN_API_URL}properties?limit=50000&sort[by]=created_at`, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": "Bearer " + token,
                    "Accept-Language": "ar"
                }
            })
            const data = await response.json();
            const worksheet = XLSX.utils.json_to_sheet(serializeIds(data.data.items));
            const workbook = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");


            const excelBuffer = XLSX.write(workbook, {bookType: 'xlsx', type: 'array'});
            const blob = new Blob([excelBuffer], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'});

            saveAs(blob, `PropertyWithHosts.xlsx`);
        }
        catch (error) {
            console.log(error);
            toast.error("حدث خطأ ما");
        }


    }
    return (
        <>

            {loading ? (
                <div className="flex justify-center items-center h-screen">
                    <HashLoader size={64} color="#36d7b7"/>
                </div>

            ) : (
                <>
                    <div><Toaster/></div>

                    <AdminLayout></AdminLayout>
                    <Content style={{direction: "rtl"}}>
                        <div className="mx-6 mt-6">
                            <Card>
                                <CardHeader>

                                    <div className="flex items-center justify-between">
                                        <div className="flex items-center">
                                            <div style={{background: "#f9f9f9"}} className={"mx-2 p-2 rounded-md"}>
                                                <House
                                                    color="#475467"
                                                    variant="Bulk"
                                                    size={32}
                                                />

                                            </div>
                                            <div>
                                                <h1 className="text-xl">
                                                    الوحدات السكنية مع أصحابها
                                                </h1>
                                            </div>


                                        </div>
                                        <DropdownMenu>
                                            <DropdownMenuTrigger asChild>
                                                <Button>
                                                    تصدير
                                                </Button>

                                            </DropdownMenuTrigger>
                                            <DropdownMenuContent className="w-56">
                                                <DropdownMenuLabel>الخيارات</DropdownMenuLabel>
                                                <DropdownMenuSeparator/>
                                                <DropdownMenuGroup>
                                                    <DropdownMenuItem onClick={() => exportToExcel(data)}
                                                                      className="gap-2">
                                                        <Icon width={32} icon="vscode-icons:file-type-excel"/>
                                                        <span>تصدير الى Excel</span>
                                                    </DropdownMenuItem>
                                                    <DropdownMenuItem onClick={() => {
                                                        try {
                                                            toast.promise(exportAllData(), {
                                                                loading: "جاري التحميل",
                                                                success: "تم تصدير البيانات بنجاح",
                                                                error: "حدث خطأ ما",
                                                            });
                                                        } catch (error) {
                                                            console.log(error);
                                                            toast.error("حدث خطأ ما");
                                                        }
                                                    }}
                                                                      className="gap-2">
                                                        <Icon width={32} icon="vscode-icons:file-type-excel"/>
                                                        <span>
                                                            تصدير  كل البيانات الى ملف اكسل
                                                        </span>
                                                    </DropdownMenuItem>

                                                    {/*<DropdownMenuItem>*/}
                                                    {/*  <Icon width={32} icon="ant-design:file-pdf-filled"  style={{color: "#333333"}} />*/}
                                                    {/*  <span>تصدير الى pdf</span>*/}
                                                    {/*</DropdownMenuItem>*/}


                                                </DropdownMenuGroup>

                                            </DropdownMenuContent>
                                        </DropdownMenu>

                                    </div>

                                </CardHeader>
                            </Card>
                        </div>
                        <div className="mx-6 mt-6">
                            <Input
                                onChange={(e) => setSearchText(e.target.value)}
                                placeholder="
                                ابحث برقم الوحدة السكنية"
                            />
                        </div>
                        <div className="mx-6 mt-6">

                            <Table
                                scroll={{x: 'max-content'}}
                                dataSource={data.filter((val: any) => {
                                    if (searchText === "") {
                                        return val;
                                    } else if (val?.id?.toString().includes(searchText)) {
                                        return val;
                                    }
                                })}
                                rowKey="id"
                                showHeader={true}
                                bordered={true}
                                sortDirections={["ascend", "descend"]}
                                showSorterTooltip={true}
                                loading={loading}
                                pagination={{
                                    showSizeChanger: true,
                                    showQuickJumper: true,
                                    showTotal: (total) => `Total ${total} items`,
                                    position: ["bottomCenter"],
                                    size: "default",
                                    itemRender: itemRender
                                }}
                                columns={columns}
                            />


                        </div>

                    </Content>
                </>
            )}


        </>
    )
}