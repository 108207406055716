import {Card, DatePicker, Input, Layout, Select, Table} from "antd";
import {useEffect, useState} from "react";
import AdminLayout from "../../../Layouts/Main";
import {useAppDispatch, useAppSelector} from "../../../redux/hooks";
import {
    CardContent,
    CardDescription,
    CardFooter,
    CardHeader,
    CardTitle,
} from "../../../components/ui/card";
import React from "react";
import toast, {Toaster} from "react-hot-toast";
import * as XLSX from 'xlsx';
import {saveAs} from 'file-saver';
import {HashLoader, PropagateLoader} from "react-spinners";
import {Link, useLocation, useNavigate} from "react-router-dom";
import {
    CalendarAdd,
    CalendarRemove,
    Clock,
    CardReceive,
    TransactionMinus, Star, User, Personalcard, House, Eye, Buliding, InfoCircle, ArrowRight2, ArrowLeft2,
} from "iconsax-react";
import {setIsAuth, setUserToken} from "../../../redux/slices/user_slice";
import {Button} from "../../../components/ui/button";
import {
    DropdownMenu,
    DropdownMenuContent, DropdownMenuGroup, DropdownMenuItem,
    DropdownMenuLabel, DropdownMenuSeparator,
    DropdownMenuTrigger
} from "../../../components/ui/dropdown-menu";
import {Icon} from "@iconify/react";
import {DateRange} from "react-day-picker";
import {addDays, format} from "date-fns";
import {Popover, PopoverContent, PopoverTrigger} from "../../../components/ui/popover";
import {Calendar} from "../../../components/ui/calendar";
import {CalendarIcon} from "lucide-react";
import {cn} from "../../../lib/utils";
import {SelectShadncn} from "../../../components/ui/select";
import {getFetcher} from "../../../API";
import {title} from "process";
import moment from "moment";
import {ColumnsType} from "antd/es/table";
import {tr} from "date-fns/locale";

const {Content} = Layout;

interface Reservation {
    id: number;
    user_id: number;
    property_id: number;
    serial: string;
    number_of_days: number;
    reserved_from: string;
    reserved_to: string;
    sub_total: number;
    service: number;
    tax: number;
    total: number;
    type: string;
    status: string;
    payment_method: string;
    property: {
        title: string;
        owner: {
            id: number;
        }
    }

    is_referral: number;
    payment_brand: string;
}

export default function Reservations() {
    const [loading, setLoading] = React.useState(true);
    const navigate = useNavigate();
    const token = useAppSelector((state) => state.user.token)
    const dispatch = useAppDispatch()
    const [data, setData] = useState([]);
    const [searchText, setSearchText] = useState("");
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const status = queryParams.get('status');
    const [date, setDate] = React.useState<DateRange | undefined>({
        from: addDays(new Date(2024, 0, 20), 10),
        to: addDays(new Date(2024, 0, 20), 20),
    })
    const [page, setPage] = useState(1)
    const [pageSize, setPageSize] = useState(10)
    const [total, setTotal] = useState(0)
    const state = useLocation().state
    useEffect(() => {
        async function fetchData() {
            try {
                const response = await fetch(`${process.env.REACT_APP_DEV_ADMIN_API_URL}reservations?payment_status=${state}&page=${page}&limit=${pageSize}&sort[by]=created_at`, {
                    method: "GET",
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": "Bearer " + token,
                        "Accept-Language": "ar",
                    }
                });
                const data = await response.json();
                setData(data.data.items);
                setPage(data.data.pagination.currentPage);
                setPageSize(data.data.pagination.perPage);
                setTotal(data.data.pagination.total);
                setLoading(false);
            } catch (error) {
                console.log(error);
                toast.error("حدث خطأ ما");
            }
        }

        if (loading) {
            fetchData();
        }
    }, [page, pageSize, state, loading]);


    useEffect(() => {
        setLoading(true);

    }, [state,]);


    const columns: ColumnsType<Reservation> = [
        {
            title: "Reservation Number",
            dataIndex: "id",
            key: "id",
            defaultSortOrder: "descend",
            sorter: (a, b) => {
                const valueA = a?.id ?? Infinity;
                const valueB = b?.id ?? Infinity;
                return valueA - valueB;
            },
        },
        {
            title: "Customer Number",
            dataIndex: "user_id",
            key: "user_id",
        },
        {
            title: "Host Number",
            dataIndex: ["property", "owner", "id"],
            key: "property.owner.id",
        },
        {
            title: "Customer name",
            dataIndex: ["user", "name"],
            key: "user.name",
        },
        {
            title: "Host name",
            dataIndex: ["property", "owner", "name"],
            key: "property.owner.name",
        },
        {
            title: "Serial",
            dataIndex: "serial",
        },
        {
            title: "Property Id",
            dataIndex: "property_id",
            key: "property_id",
            sorter: (a, b) => {
                const valueA = a?.property_id ?? Infinity;
                const valueB = b?.property_id ?? Infinity;
                return valueA - valueB;
            },
        },
        {
            title: "Number of Days",
            dataIndex: "number_of_days",
            key: "number_of_days",
            render: (text: any, record: any) => {
                const dayIn = moment(record.reserved_from);
                const dayOut = moment(record.reserved_to);
                const duration = dayOut.diff(dayIn, 'days');
                return duration;
            },
            sorter: (a, b) => {
                const valueA = a?.number_of_days ?? Infinity;
                const valueB = b?.number_of_days ?? Infinity;
                return valueA - valueB;
            },
        },
        {
            title: "Day in",
            dataIndex: "reserved_from",
            key: "reserved_from",
        },
        {
            title: "Day out",
            dataIndex: "reserved_to",
            key: "reserved_to",
        },
        {
            title: "Sub Total",
            dataIndex: "sub_total",
            sorter: (a, b) => {
                const valueA = a?.sub_total ?? Infinity;
                const valueB = b?.sub_total ?? Infinity;
                return valueA - valueB;
            },
        },
        {
            title: "Service Fee",
            dataIndex: "service",
            sorter: (a, b) => {
                const valueA = a.service ?? Infinity;
                const valueB = b.service ?? Infinity;
                return valueA - valueB;
            },
        },
        {
            title: "Tax",
            dataIndex: "tax",
            sorter: (a, b) => {
                const valueA = a.tax ?? Infinity;
                const valueB = b.tax ?? Infinity;
                return valueA - valueB;
            },
        },
        {
            title: "Paid Amount",
            dataIndex: "total",
            key: "total",
            sorter: (a, b) => {
                const valueA = a.total ?? Infinity;
                const valueB = b.total ?? Infinity;
                return valueA - valueB;
            },
        },
        {
            title: "Reservation type",
            dataIndex: "type",
            key: "type",
            sorter: (a, b) => {
                const valueA = a.type ?? "zzzzzz";
                const valueB = b.type ?? "zzzzzz";
                return valueA.localeCompare(valueB);
            },
        },
        {
            title: "Property Name",
            dataIndex: ["property", "title"],
            sorter: (a, b) => {
                const valueA = a.property?.title ?? "zzzzzz";
                const valueB = b.property?.title ?? "zzzzzz";
                return valueA.localeCompare(valueB);
            },
        },
        {
            title: "Status",
            dataIndex: "status",
            key: "status",
            sorter: (a, b) => {
                const valueA = a.status ?? "zzzzzz";
                const valueB = b.status ?? "zzzzzz";
                return valueA.localeCompare(valueB);
            },
        },
        {
            title: "Payment Type",
            dataIndex: "payment_method",
            key: "payment_method",
            sorter: (a, b) => {
                const valueA = a.payment_method ?? "zzzzzz";
                const valueB = b.payment_method ?? "zzzzzz";
                return valueA.localeCompare(valueB);
            },

        },
        {
            title: "Payment Brand",
            dataIndex: "payment_brand",
            key: "payment_brand",
            sorter: (a, b) => {
                const valueA = a.payment_brand ?? "zzzzzz";
                const valueB = b.payment_brand ?? "zzzzzz";
                return valueA.localeCompare(valueB);
            },
        },
        {
            title: "is Referral",
            key: "iReferal",
            render: (text: any, record: any) => {
                return record.iReferal == 1 ? "Yes" : "No"
            }

        },
        {
            title: "Action",
            key: "action",
            render: (text, record) => (
                <>
                    <DropdownMenu>
                        <DropdownMenuTrigger>الإجراءات</DropdownMenuTrigger>
                        <DropdownMenuContent>
                            <DropdownMenuLabel>الإجراءات</DropdownMenuLabel>
                            <DropdownMenuSeparator/>
                            <DropdownMenuItem
                                onClick={() => navigate("/admin/reservation/view", {state: record})}
                            >
                                عرض الحجز
                            </DropdownMenuItem>
                        </DropdownMenuContent>
                    </DropdownMenu>
                </>
            ),
        },
    ];
    const serializeIds = (data: any[]) => {
        return data.map((item) => {
            return {
                "Reservation Number": item.id,
                "Customer Number": item.user_id,
                "Host Number": item.property.owner.id,
                "Customer Name": item.user?.name,
                "Host Name": item.property.owner.name,
                "Serial": item.serial,
                "Property Id": item.property_id,
                "Number of Days": item.number_of_days,
                "Day In": item.reserved_from,
                "Day Out": item.reserved_to,
                "Sub Total": item.sub_total,
                "Service Fee": item.service,
                "Tax": item.tax,
                "Paid Amount": item.total,
                "Reservation Type": item.type,
                "Property Name": item.property.title,
                "Status": item.status,
                "Payment Type": item.payment_method,
                "Payment Brand": item.payment_brand,
                "Is Referral": item.is_referral == 1 ? "Yes" : "No"
            };
        });
    };


    const exportToExcel = (data: any[]) => {
        const serializedData = serializeIds(data); // Serialize IDs before exporting
        const worksheet = XLSX.utils.json_to_sheet(serializedData);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");

        const excelBuffer = XLSX.write(workbook, {bookType: 'xlsx', type: 'array'});
        const blob = new Blob([excelBuffer], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'});

        saveAs(blob, "reservations.xlsx");
    };

    const itemRender = (_: any, type: string, originalElement: any) => {
        console.log(type, "a");
        if (type === "prev") {
            return <ArrowRight2 size="32" color="#333"/>;
        }
        if (type === "next") {
            return <ArrowLeft2 size="32" color="#333"/>;
        }
        return originalElement;
    };

    const exportAllData = async () => {

        const response = await fetch(`${process.env.REACT_APP_DEV_ADMIN_API_URL}reservations?payment_status=${state}&limit=10000&sort[by]=created_at`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + token,
                "Accept-Language": "ar",
            }
        })
        const data = await response.json();
        const worksheet = XLSX.utils.json_to_sheet(serializeIds(data.data.items));

        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");

        const excelBuffer = XLSX.write(workbook, {bookType: 'xlsx', type: 'array'});
        const blob = new Blob([excelBuffer], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'});

        saveAs(blob, `reservations.xlsx`);


    }


    return (
        <>
            {/*{loading ? (*/}
            {/*  <div className="flex justify-center items-center h-screen">*/}
            {/*    <HashLoader size={64} color="#36d7b7" />*/}
            {/*  </div>*/}
            {/*) : (*/}
            <>
                <div>
                    <Toaster/>
                </div>

                <AdminLayout></AdminLayout>
                <Content style={{direction: "rtl"}}>
                    <div className="mx-6 mt-6">
                        <Card>
                            <CardHeader>
                                <div className="flex items-center justify-between">
                                    <div className="flex items-center gap-2">
                                        <div style={{background: "#f9f9f9"}} className={"mx-2 p-2 rounded-md"}>
                                            <TransactionMinus size="48" color="#2dacf4" variant="Bulk"/>

                                        </div>
                                        <h1 className="text-xl">الحجوزات</h1>

                                    </div>
                                    <DropdownMenu>
                                        <DropdownMenuTrigger asChild>
                                            <Button>
                                                تصدير
                                            </Button>

                                        </DropdownMenuTrigger>
                                        <DropdownMenuContent className="w-56">
                                            <DropdownMenuLabel>الخيارات</DropdownMenuLabel>
                                            <DropdownMenuSeparator/>
                                            <DropdownMenuGroup>
                                                <DropdownMenuItem onClick={() => exportToExcel(data)} className="gap-2">
                                                    <Icon width={32} icon="vscode-icons:file-type-excel"/>
                                                    <span>تصدير الى Excel</span>
                                                </DropdownMenuItem>
                                                {/*<DropdownMenuItem>*/}
                                                {/*  <Icon width={32} icon="ant-design:file-pdf-filled"  style={{color: "#333333"}} />*/}
                                                {/*  <span>تصدير الى pdf</span>*/}
                                                {/*</DropdownMenuItem>*/}
                                                <DropdownMenuItem onClick={() => {
                                                    try {
                                                        toast.promise(exportAllData(), {
                                                            loading: "جاري التحميل",
                                                            success: "تم تصدير البيانات بنجاح",
                                                            error: "حدث خطأ ما",
                                                        });
                                                    } catch (error) {
                                                        console.log(error);
                                                        toast.error("حدث خطأ ما");
                                                    }
                                                }}
                                                                  className="gap-2">
                                                    <Icon width={32} icon="vscode-icons:file-type-excel"/>
                                                    <span>
                                                            تصدير  كل البيانات الى ملف اكسل
                                                        </span>
                                                </DropdownMenuItem>

                                            </DropdownMenuGroup>

                                        </DropdownMenuContent>
                                    </DropdownMenu>


                                </div>
                            </CardHeader>
                        </Card>
                    </div>
                    <div className="mt-6 mx-6">
                        <Input
                            placeholder="
               بحث عن الحجز برقم الحجز او رقم السريال او رقم العميل او رقم الوحدة او اسم الوحدة او رقم المالك او الحالة او طريقة الدفع

"
                            value={searchText}
                            onChange={(e) => setSearchText(e.target.value)}

                        />
                    </div>

                    <div className="mt-6 mx-6">

                        <Table
                            scroll={{x: 'max-content'}}
                            dataSource={data.filter((val: any) => {
                                if (searchText == "") {
                                    return val
                                } else if (
                                    val?.id?.toString().includes(searchText) ||
                                    val?.user_id?.toString().includes(searchText) ||
                                    val?.property_id?.toString().includes(searchText) ||
                                    (val?.property?.title && val.property.title.includes(searchText)) ||
                                    (val?.property?.owner?.id && val.property.owner.id.toString().includes(searchText)) ||
                                    (val?.serial && val.serial.includes(searchText)) ||
                                    (val?.reserved_from && val.reserved_from.includes(searchText)) ||
                                    (val?.reserved_to && val.reserved_to.includes(searchText)) ||
                                    (val?.sub_total && val.sub_total.toString().includes(searchText)) ||
                                    (val?.service && val.service.toString().includes(searchText)) ||
                                    (val?.tax && val.tax.toString().includes(searchText)) ||
                                    (val?.total && val.total.toString().includes(searchText)) ||
                                    (val?.type && val.type.includes(searchText)) ||
                                    (val?.status && val.status.includes(searchText)) ||
                                    (val?.payment_method && val.payment_method.includes(searchText))
                                ) {
                                    return val
                                }
                            })}

                            rowKey="id"
                            showHeader={true}
                            bordered={true}
                            sortDirections={["ascend", "descend"]}
                            showSorterTooltip={true}


                            loading={loading}
                            pagination={{
                                showSizeChanger: true,
                                showQuickJumper: true,
                                showTotal: (total) => `Total ${total} items`,
                                total: total,
                                current: page,
                                position: ["bottomCenter"],
                                size: "default",
                                itemRender: itemRender


                            }}
                            onChange={(pagination, filters, sorter, extra) => {
                                console.log('params', pagination, filters, sorter, extra);
                                setPage(pagination.current ?? 1)
                                setPageSize(pagination.pageSize ?? 10)

                                setLoading(true)
                            }}

                            columns={columns}></Table>
                    </div>


                </Content>
            </>
            {/*)}*/}
        </>
    );
}
